@import "global";
.anywhale {
	$this:&;
	z-index: 3;

	&__letter {
		left:245px;
	}

	.citrus {
		top: 0;
		right: -83px;
		background-color: rgba(#5d2aca,0.1);
	}

	.section__cta {
		background-color: #5d2aca;
		&:hover {
			background-color: darken(#5d2aca, 4);
		}

		.arrow path {
			fill: #7433f3;
		}

		&:after {
			background-color: #5d2aca;
		}

	}
}